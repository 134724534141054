<template>
  <div>
        <!-- faq search section -->

    <b-card
      no-body
      class="faq-search"
      :style="{backgroundImage:`url(${require('@/assets/images/banner/banner.png')})`}"
    >
      <b-card-body class="text-center">
        <h2 class="text-primary">
          NOTICE
<!--                <div-->
<!--                  class="text-center"-->
<!--                  style="color:rgb(248,241,122); font-size: x-small;"-->
<!--                >-->
<!--                  {{ noticeStore }}-->
<!--                </div>-->
          <b-badge
              variant="primary"
              style="font-size: xx-small"
          >
            DB 연계
          </b-badge>
        </h2>
        <b-card-text class="mb-2">
          종목별 게임 방법, 배당, 충전/환전 등 자주 묻는 질문을 검색하세요
        </b-card-text>

        <!-- form -->
        <b-form class="faq-search-input">
          <b-input-group class="input-group-merge">
            <b-form-input id="filterInput" v-model="filter" type="search" placeholder="검색" />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''" > Clear </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form>
        <!-- form -->
      </b-card-body>
    </b-card>

    <!--/ faq search section -->

    <b-card>
      <b-row>
        <!-- Filter -->
        <!--
        <b-col md="12" class="my-1">
          <b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0" >
            <b-input-group size="sm">
              <b-form-input id="filterInput" v-model="filter" type="search" placeholder="검색" />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''" > Clear </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        -->
        <!-- Filter. -->
        <!-- bs-table -->
        <b-col md="12" class="my-1">
          <b-table
            show-empty
            striped
            hover
            responsive
            empty-text="(1) 검색결과가 없습니다."
            empty-filtered-text="(2) 검색결과가 없습니다."
            :items="fetchBoard"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
            @row-clicked="item=>$set(item, '_showDetails', !item._showDetails)"
            class="text-center"
          >
            <template #cell(index)="data"
                      size="sm"
                      style="text-align: center; min-width: 3rem"
            >
              {{ totalRows - (perPage * (currentPage - 1)) - data.index }}
            </template>
            <template #cell(title)="data">
              <div
                  size="sm"
                  style="text-align: left; min-width: 15rem"
              >
                {{ data.item.title }}
              </div>
            </template>
            <template #cell(createdAt)="data">
              <div
                  size="sm"
                  style="text-align: center; min-width: 7rem"
              >
                {{ data.item.createdAt | formatDate('YYYY-MM-DD HH:mm') }}
              </div>
            </template>
            <template #cell(updatedAt)="data">
              <div
                  size="sm"
                  style="text-align: center; min-width: 7rem"
              >
                {{ data.item.updatedAt | formatDate('YYYY-MM-DD HH:mm') }}
              </div>
            </template>
            <template #row-details="row">
              <b-card class="innerCard m-0" style="background-color: #1b2337">
<!--                <b-col cols="12">-->
<!--                  <b-form-group-->
<!--                    class="font-small-3"-->
<!--                    label-for="main-notice"-->
<!--                  >-->
<!--                    <div class="mt-2">-->
                      <quill-editor
                        ref="myTextEditor"
                        placeholder="공지사항 상세내용"
                        v-model="row.item.content"
                        :disabled="true"
                        :options="editorOption"
                      />
<!--                    </div>-->
<!--                  </b-form-group>-->
<!--                </b-col>-->
              </b-card>
            </template>
          </b-table>
<!--          <b-modal-->
<!--              id="modal-lg"-->
<!--              ref="my-modal"-->
<!--              small-->
<!--              title="상세조회"-->
<!--              centered-->
<!--              button-size="sm"-->
<!--              size="lg"-->
<!--              style="max-width: 400px !important;"-->
<!--              hide-footer-->
<!--              ok-only-->
<!--          >-->
<!--            <b-col cols="12">-->
<!--              <div-->
<!--                  class="text-info"-->
<!--              >-->
<!--                {{ detailTitle }}-->
<!--              </div>-->
<!--            </b-col>-->
<!--            <hr>-->
<!--&lt;!&ndash;                <b-form-textarea&ndash;&gt;-->
<!--&lt;!&ndash;                    id="faq-detail"&ndash;&gt;-->
<!--&lt;!&ndash;                    class="font-small-3"&ndash;&gt;-->
<!--&lt;!&ndash;                    size="sm"&ndash;&gt;-->
<!--&lt;!&ndash;                    placeholder="FAQ상세내용"&ndash;&gt;-->
<!--&lt;!&ndash;                    style="color:rgb(229,166,48)"&ndash;&gt;-->
<!--&lt;!&ndash;                    rows="9"&ndash;&gt;-->
<!--&lt;!&ndash;                    v-model="detailContent"&ndash;&gt;-->
<!--&lt;!&ndash;                    :disabled="true"&ndash;&gt;-->
<!--&lt;!&ndash;                />&ndash;&gt;-->
<!--                <div class="mt-2">-->
<!--                  <quill-editor-->
<!--                      ref="myTextEditor"-->
<!--                      v-model="detailContent"-->
<!--                      class="editor"-->
<!--                      :disabled="true"-->
<!--                      :options="editorOption"-->
<!--                  />-->
<!--                </div>-->
<!--          </b-modal>-->
        </b-col>
        <!-- bs-table. -->
        <!-- pagination -->
        <b-col cols="12" >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            align="center"
            class="my-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
        <!-- pagination. -->
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BCardText, BForm, BFormInput, BFormGroup, BButton, BButtonToolbar,
  BTable, BAvatar, BBadge, BFormSelect, BPagination, BInputGroup, BInputGroupAppend, BFormTextarea,
} from 'bootstrap-vue'

import { apolloClient } from '@/vue-apollo'
import { FindManyNoticeBoardDocument } from '@/generated/graphql'

import Ripple from 'vue-ripple-directive'
import { createNamespacedHelpers } from 'vuex'

import { quillEditor } from 'vue-quill-editor'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

const noticeStore = createNamespacedHelpers('noticeStore')

import { FETCH_BOARD } from '@/store/notice/action'

export default {
  components: {
    BFormTextarea,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BButtonToolbar,
    BTable,
    BAvatar,
    BBadge,
    BFormSelect,
    BPagination,
    BInputGroup,
    BInputGroupAppend,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      items: [],
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      filterOn: [],
      detailContent: '',
      detailTitle: '',
      isEditing: true,
      fields: [
        {
          key: 'index', sortable: false, label: 'No.', thClass: 'col1',
        },
        {
          key: 'title', sortable: false, label: '제목', thClass: 'col2',
        },
        {
          key: 'enrollId', sortable: false, label: '등록자', thClass: 'col3',
        },
        {
          key: 'createdAt', sortable: false, label: '등록일', thClass: 'col4',
        },
        {
          key: 'updatedAt', sortable: false, label: '수정일', thClass: 'col5',
        },
      ],
      editorOption: {
        modules: {
          toolbar: [
            [''],
          ],
        },
      },
    }
  },
  // 커스텀 필터 설정
  // filters : {
  //   // filter로 쓸 filter ID 지정
  //   yyyyMMdd : function(value){ //  yyyy MM dd 형태로 만들어줄거기 때문에 이렇게 이름을 지었음
  //     // 들어오는 value 값이 공백이면 그냥 공백으로 돌려줌
  //     if(value == '') return '';
  //
  //     // 현재 Date 혹은 DateTime 데이터를 javaScript date 타입화
  //     var js_date = new Date(value);
  //
  //     // 연도, 월, 일 추출
  //     var year = js_date.getFullYear();
  //     var month = js_date.getMonth() + 1;
  //     var day = js_date.getDate();
  //
  //     // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리
  //     if(month < 10){
  //       month = '0' + month;
  //     }
  //
  //     if(day < 10){
  //       day = '0' + day;
  //     }
  //
  //     // 최종 포맷 (ex - '2021-10-08')
  //     return year + '-' + month + '-' + day;
  //   }
  // },
  computed: {
    ...noticeStore.mapGetters({
      fetchBoard: 'fetchBoard',
    }),
    noticeStore() {
      return this.$store.state.noticeStore.fetchBoard
      // return this.detailContent
    },
  },
  mounted() {
    this.$socket.emit('handleMenuEntry', {
      site: this.$site,
      userid: JSON.parse(localStorage.getItem('userData')).userid,
      menu: 'notice',
      menuCategory: '',
    })
    this.fetchData()
  },
  beforeDestroy() {
    this.$socket.emit('handleMenuEntry', {
      site: this.$site,
      userid: JSON.parse(localStorage.getItem('userData')).userid,
      menu: '',
      menuCategory: '',
    })
  },
  methods: {
    ...noticeStore.mapActions({
      $fetchBoard: FETCH_BOARD,
    }),
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async fetchData() {
      // const mySite = this.userData.site
      await this.$fetchBoard({
        site: this.$site,
      })
      this.totalRows = this.fetchBoard.length
    },
    rowClickHandler(record, index, type) {
      this.detailTitle = record.title
      this.detailContent = record.content
      this.$refs['my-modal'].show()
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/pages/page-notice.scss';

  .b-table tbody tr:hover td {
      background: rgb(99,96,108) !important;
      //color: rgb(44, 6, 6) !important;
  }
  .ql-container{ margin-left: 0rem !important}
  .ql-container.ql-snow{ border: none !important; background-color: rgb(57, 64, 86)
  }
  .ql-toolbar.ql-snow{ border: none !important; display: none !important}
  .innerCard .card-body{ padding: 0 !important}
</style>
